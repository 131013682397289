<template>
 <!-- v-show="!manualRefreshMatchDetail" -->
  <div class="navtab-wrapper has-space">
    <template v-if="!isLogin">
      <router-link
        class="nav-pill"
        :to="{ name: navTab[0].pathname }"
        :key="navTab[0].name"
      >
        <span>{{ $t(navTab[0].name) }}</span>
      </router-link>
    </template>
    <template v-if="isLogin">
      <router-link
        class="nav-pill"
        :to="{ name: o.pathname }"
        v-for="o in navTab"
        :key="o.name"
      >
        <span>{{ $t(o.name) }}</span>
      </router-link>
    </template>
  </div>
  <router-view :matchDetailScore="matchDetailScore"></router-view>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ["matchDetailScore"],
  data() {
    return {
      navTab: [
        { name: "DETAILS", pathname: "basketballStatsAnalysis" },// ANALYSIS
        { name: "HANDICAP_ODDS", pathname: "basketballStatsHandicap" },
        { name: "OVER_UNDER_ODDS", pathname: "basketballStatsGoals" },
        { name: "EUROPE_ODDS", pathname: "basketballStatsWDL" },
      ],
    };
  },
  computed: {
    ...mapGetters(['manualRefreshMatchDetail','isLogin']),
  },
};
</script>

<style scoped>
.router-link-exact-active {
  background-color: var(--color-hover);
  border: 0.1rem solid var(--color-hover);
  color: black;
  font-weight: 700;
}
</style>
